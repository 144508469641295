<template>

  <v-card :loading="loaders.loading" flat>

    <v-card-title>

    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" lg="4" md="4">
          <image-upload-controller v-if="loaders.ready"
                                   :image="chapter.cover_src"
                                   :url="'upload.chapter'+(chapter.id ? '?id='+chapter.id : '')"
                                   :keyform="'chapter_form[cover]'"
                                   @remove="()=> chapter.cover_image = ''"
                                   @uploaded="coverUploaded"
          ></image-upload-controller>
        </v-col>
        <v-col cols="12" lg="8" md="8">
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>

      <h3>Detail du chapitre</h3>
      <v-row>
        <v-col>
          <v-select
            :loading="loaders.categories"
            v-model="chapter.category_id"
            outlined
            dense
            :items="categories"
            label="Categorie"
            item-value="id"
            item-text="name"
          >
            <template v-slot:selection="data">
              {{ data.item.name['fr'] }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                {{ data.item.name['fr'] }}
              </v-list-item-content>
            </template>
          </v-select>
          <label for="">Sens de lecture  </label>
          <v-radio-group v-model="chapter.readingdirection">
            <v-radio label="De la gauche vers la droite" :value="0"></v-radio>
            <v-radio label="De la droite vers la gauche" :value="1"></v-radio>
            <v-radio label="Du haut vers le bas" :value="2"></v-radio>
          </v-radio-group>
          <v-text-field outlined v-model="chapter.title" label="Titre"></v-text-field>

          <v-textarea outlined v-model="chapter.synopsis" label="Synopsis"></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loaders.loading" @click="save(false)">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mt-6 pb-16" v-if="chapter.id">
      <v-switch
        v-model="chapter.status"
        hide-details
        value="1"
        true-value="1"
        false-value=""
        :label="` ${chapter.status == 1 ? 'Public' : 'Brouillon'}`"
      ></v-switch>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="loaders.loading" @click="save(1)">
        Publier
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
// import { useMeta } from 'vue-meta'

export default {
  components: {
    ChapterFormComponent,
    ImageUploadController,
    ProfileItem, FollowToggleComponent, Banner
  },
  setup() {
    //useMeta({ title: 'Some Page' })
    const dialog = ref({
      newchapter: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: true,
      ready: false,
    })

    const {router} = useRouter()
    const route = router.currentRoute

    const user_id = ref(localStorage.getItem('user_id'))
    const user = ref(Drequest.getUser())

    const chapter = ref({id: parseInt(route.params.idchapter)})
    const e1 = ref(1)
    const cover = ref(1)
    const categories = ref([])

    Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=category')
      .get(response => {
        console.log(response)
        categories.value = response.listEntity
        loaders.value.categories = false
      })
    if (chapter.value.id)
      Drequest.api('detail.chapter?id=' + chapter.value.id).get((response) => {
        loaders.value.loading = false
        loaders.value.ready = true
        chapter.value = response.chapter
      });
    else
      loaders.value.ready = true

    const coverUploaded = (response) => {
      console.log(response)
      chapter.value.cover_image = response.chapter.cover_image
    }

    const save = (publish) => {

      loaders.value.loading = true
      let url;
      if (chapter.value.id)
        url = 'update.chapter?id=' + chapter.value.id
      else
        url = 'chapter.create'

      Drequest.api(url)
        .data(
          {
            chapter: {
              'user.id': user.value.id,
              'title': chapter.value.title,
              status: chapter.value.status,
              cover_image: chapter.value.cover_image,
              //'tree_item\\category.id': post.value.category.id,
              'synopsis': chapter.value.synopsis //$("#postcontent").html(),
            }
          })
        .raw((response) => {

          loaders.value.loading = false
          if (response.success) {
            if (url === 'chapter.create') {
              chapter.value.id = response.chapter.id
              e1.value = 2
              chapterll.value.listEntity.push(response.chapter)
            } else if (publish) {
              chapter.value = {}
              dialog.value.newchapter = false
              e1.value = 1
            }
          }

        })

    }

    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,
      coverUploaded,
      save,

      categories,
      user,
      loaders,
      chapter,
      e1,
      cover,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
